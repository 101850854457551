import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Languages } from '../utils/constants'
import Dataset from './Opponent.json'
import EnglishGameLanguageSet from '../Languages/eng.json'
import SpanishGameLanguageSet from '../Languages/esp.json'
import FrenchGameLanguageSet from '../Languages/fr.json'
import EnglishChessLanguageSet from '../Languages/chessLanguages/eng.json'
import SpanishChessLanguageSet from '../Languages/chessLanguages/esp.json'
import FrenchChessLanguageSet from '../Languages/chessLanguages/fr.json'

const LanguageMapping = {
  [Languages.english]: {
    game: EnglishGameLanguageSet,
    chess: EnglishChessLanguageSet,
  },
  [Languages.spanish]: {
    game: SpanishGameLanguageSet,
    chess: SpanishChessLanguageSet,
  },
  [Languages.french]: {
    game: FrenchGameLanguageSet,
    chess: FrenchChessLanguageSet,
  },
};

export const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
  const isMobile = useMediaQuery({
    query: `only screen and (max-width: 768px)`,
  });

  const isTablet = useMediaQuery({
    query: `only screen and (min-width: 768px) and (max-width: 1024px)`,
  });

  const isDesktop = useMediaQuery({
    query: `only screen and (min-width: 1025px)`,
  });

  const [state, setState] = useState(() => {
    return {
      audio: true,
      video: true,
      opponentAudio: false,
      opponentVideoPlaying: true,
      loadWelcomeVideo: false,
      language: localStorage.getItem("chessLanguage") || Languages.english,
      languageJson: LanguageMapping[Languages.english],
      opponentInfo: null,
      chessDimensions: {
        height: 0,
        width: 0,
      },
      media: {
        isMobile,
        isTablet,
        isDesktop,
      },
    };
  });

  // load opponent info
  useEffect(() => {
    const index = Math.floor(Math.random() * 3) + 1;
    const _opponent = Dataset.filter((el) => el.language === state.language)[
      index - 1
    ];

    setState((prev) => ({
      ...prev,
      opponentInfo: _opponent,
      languageJson: LanguageMapping[state.language],
    }));
  }, [state.language]);

  return (
    <LayoutContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
