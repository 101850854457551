import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './app'

import { GameProvider, LayoutProvider, WidgetProvider } from './store'

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>
        <LayoutProvider>
            <WidgetProvider>
                <GameProvider>
                    <App/>
                </GameProvider>
            </WidgetProvider>
        </LayoutProvider>
    </React.StrictMode>
);
