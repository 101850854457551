import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { withOrientationChange } from 'react-device-detect'

import { useWindowDims } from './hooks/useDimensions'
import { Game, Hamburger, LandscapeWarning, Menu, Navbar, Sidebar, StatsModal } from './components'

import { FAQ, Info, Language, Stats } from './components/modals'

import './app.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { motion } from 'framer-motion'
import { GameContext, LayoutContext } from './store'
import { Orientation } from './utils/constants'

const renderModal = (type, toggleModal) => {
    switch (type) {
        case "stats":
            return <Stats/>;
        case "language":
            return <Language toggleModal={toggleModal}/>;
        case "info":
            return <Info/>;
        case "faq":
            return <FAQ/>;

        default:
            return <div></div>;
    }
};

export const App = withOrientationChange((props) => {
    const { state: layoutState } = useContext(LayoutContext);
    const { state: gameState } = useContext(GameContext);

    const { media } = layoutState;

    const { width, height, orientation } = useWindowDims();
    const [openMenu, setOpenMenu] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [modalType, setModalType] = useState(false);

    const toggleMenu = () => setOpenMenu(!openMenu);

    const toggleModal = (type, flag) => {
        setOpenMenu(false);
        setopenModal(typeof flag === "undefined" ? !openModal : flag);
        setModalType(type);
    };

    useEffect(() => {
        const { myWin, myLoss, myDraw } = gameState.score

        if ((myWin || myLoss || myDraw) && !openMenu) {
            toggleMenu();
        }
    }, [gameState.score])

    const constraintsRef = useRef(null);

    const isLandscape = Orientation.LANDSCAPE === orientation;
    const isPortrait = Orientation.PORTRAIT === orientation;
    const { isMobile, isTablet, isDesktop } = media;

    const portraitView = isMobile || (isTablet && isPortrait);
    const landscapeView = isDesktop || (isTablet && isLandscape);

    return (
        <motion.div
            className="app"
            style={{
                width,
                height,
            }}
            ref={constraintsRef}
        >
            {isMobile && isLandscape && <LandscapeWarning/>}

            {portraitView && (
                <Fragment>
                    <Navbar/>
                    <Hamburger opened={openMenu} onClick={toggleMenu}/>
                    <Menu
                        opened={openMenu}
                        toggleMenu={toggleMenu}
                        toggleModal={toggleModal}
                    />
                    <StatsModal opened={openModal} toggle={toggleModal}>
                        {renderModal(modalType, toggleModal)}
                    </StatsModal>
                </Fragment>
            )}

            {landscapeView && (
                <Fragment>
                    <Sidebar/>
                </Fragment>
            )}

            <Game portraitView={portraitView} landscapeView={landscapeView}/>
        </motion.div>
    );
});
