import React from 'react'

import './navbar.scss'
import PalatarLogo from '../assets/ludo.webp'
import ExitIcon from '../assets/exit.svg'

export const Navbar = () => {
  return (
    <div className="navbar-container">
      <div className="content">
        <div className="nav-item logo">
          <img data-cy="navbar-logo" src={PalatarLogo} alt="palatar-logo" onClick={()=>{
            window.sendToGA('Palatar Logo Clicked from Top Right Corner');
            window.location.href = process.env.REACT_APP_REDIRECT_URL;
          }}/>
        </div>
        <div data-cy="navbar-exit" className="nav-item navbar-action">
          <img src={ExitIcon} alt="exit-icon" onClick={()=>{
            window.sendToGA('Exit Button Clicked from Top Right Corner');
            window.location.href = process.env.REACT_APP_REDIRECT_URL;
          }}/>
        </div>
      </div>
    </div>
  );
};
