import clsx from 'clsx'
import React, { Fragment, useContext } from 'react'
import { LayoutContext } from '../../store'
import { Languages } from '../../utils/constants'

export const Language = ({toggleModal}) => {
  const { state: layoutState, setState: setLayoutState } =
    useContext(LayoutContext);

  const { languageJson, language } = layoutState;

  const changeLanguage = (language) => {
    setLayoutState((prev) => ({ ...prev, language }));
  };

  return (
    <Fragment>
      <p className="title text-center">
        {languageJson.game.CHESSlanguagePopoverHeader}
      </p>
      <div>
        <p
          className={clsx({
            "fs-1": true,
            "t-orange": true,
            "text-center": true,
            "mb-5": true,
            "mt-5": true,
            "language-active": language === Languages.english,
          })}
          onClick={()=> {
              changeLanguage(Languages.english);
              setTimeout(() => {
                  toggleModal()
              }, 1000)}
        }
        >
          English (en)
        </p>

        <p
          className={clsx({
            "fs-1": true,
            "t-orange": true,
            "text-center": true,
            "mb-5": true,
            "mt-5": true,
            "language-active": language === Languages.french,
          })}
          onClick={()=> {
              changeLanguage(Languages.french);
              setTimeout(() => {
                  toggleModal()
              }, 1000)}
          }
        >
          Français (fr)
        </p>

        <p
          className={clsx({
            "fs-1": true,
            "t-orange": true,
            "text-center": true,
            "mb-5": true,
            "mt-5": true,
            "language-active": language === Languages.spanish,
          })}
          onClick={()=> {
              changeLanguage(Languages.spanish);
              setTimeout(() => {
                  toggleModal()
              }, 1000)}
          }
        >
          Español (esp)
        </p>
      </div>
    </Fragment>
  );
};
