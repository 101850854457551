import React, { useContext, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import clsx from 'clsx'
import { VscGlobe } from 'react-icons/vsc'
import { BsInfo, BsQuestion } from 'react-icons/bs'
import PalatarLogo from '../assets/ludo.webp'
import ExitIcon from '../assets/exit.svg'
import GrScoreCard from '../images/GrScoreCard.svg'
import json from '../../package.json'
import './sidebar.scss'

import { GameContext, LayoutContext } from '../store'
import { Languages } from '../utils/constants'

export const Sidebar = () => {
    const { state: gameState } = useContext(GameContext)
    const { state: layoutState, setState: setLayoutState } = useContext(LayoutContext)
    const { myWin, myLoss, myDraw, oppWin, oppLoss, oppDraw } = gameState.score
    const { languageJson, opponentInfo, language } = layoutState

    const [show, setShow] = useState(false)
    const [languageShow, setLanguageShow] = useState(false)
    const [infoShow, setInfoShow] = useState(false)
    const [faqShow, setFaqShow] = useState(false)
    const [scoreShow, setScoreShow] = useState(false)

    const changeLanguage = (language) => {
        setLayoutState((prev) => ({ ...prev, language }))
        localStorage.setItem('chessLanguage', language)
    }

    const logoPopup = (
        <Popover id="popover-basic">
            <Popover.Body>{ languageJson.game.CHESSlogoHoverText }</Popover.Body>
        </Popover>
    )

    const languagePopover = (
        <Popover id="popover-language" style={ { width: '300px' } }>
            <Popover.Header as="h3">
                <div>
                    { languageJson.game.CHESSlanguagePopoverHeader }
                    <span data-cy="sidebar-globe-exit"
                          style={ { float: 'right', cursor: 'pointer' } }
                          onClick={ () => {
                              setLanguageShow(false)
                          } }>
                        X
                    </span>
                </div>
            </Popover.Header>
            <Popover.Body>
                <p data-cy="sidebar-globe-eng"
                   className={ clsx({ 'languagePreference': true, 'fw-bold': language === Languages.english }) }
                   onClick={ () => {
                       changeLanguage(Languages.english)
                       window.sendToGA('Change Language to English Button Clicked')
                   } }
                >
                    English (en)
                </p>
                <p data-cy="sidebar-globe-fr"
                   className={ clsx({ 'languagePreference': true, 'fw-bold': language === Languages.french }) }
                   onClick={ () => {
                       changeLanguage(Languages.french)
                       window.sendToGA('Change Language to French Button Clicked')
                   } }
                >
                    Français (fr)
                </p>
                <p data-cy="sidebar-globe-esp"
                   className={ clsx({ 'languagePreference': true, 'fw-bold': language === Languages.spanish }) }
                   onClick={ () => {
                       changeLanguage(Languages.spanish)
                       window.sendToGA('Change Language to Spanish Button Clicked')
                   } }
                >
                    Español (esp)
                </p>
            </Popover.Body>
        </Popover>
    )

    const infoPopover = (
        <Popover id="popover-info" style={ { width: '300px' } }>
            <Popover.Header as="h3">
                <div>
                    { languageJson.game.CHESSinfoPopoverHeader }
                    <span data-cy="sidebar-info-close" style={ { float: 'right', cursor: 'pointer' } } onClick={ () => {
                        setInfoShow(false)
                    } }>
                        X
                    </span>
                </div>
            </Popover.Header>
            <Popover.Body>
                { languageJson.game.CHESSinfoPopoverPart1.split('\n').map((el) => [el, <br/>]) }
                <br/>
                <br/>
                { languageJson.game.CHESSinfoPopoverPart2.split('\n').map((el) => [el, <br/>]) }
                <br/>
                <br/>
                { languageJson.game.CHESSinfoPopoverPart3.split('\n').map((el) => [el, <br/>]) }
            </Popover.Body>
        </Popover>
    )

    const helpPopover = (
        <Popover id="popover-help" style={ { width: '300px' } }>
            <Popover.Header as="h3">
                <div>
                    { languageJson.game.CHESShelpPopoverHeader }
                    <span data-cy="sidebar-faq-close" style={ { float: 'right', cursor: 'pointer' } } onClick={ () => {
                        setFaqShow(false)
                    } }>
                        X
                    </span>
                </div>
            </Popover.Header>
            <Popover.Body>
                <h3>{ languageJson.game.CHESShelpPopoverPart1.split('\n').map((el) => [el, <br/>]) }</h3>
                <h4>{ languageJson.game.CHESShelpPopoverPart5.split('\n').map((el) => [el, <br/>]) }</h4><br/>
                <h3>{ languageJson.game.CHESShelpPopoverPart2.split('\n').map((el) => [el, <br/>]) }</h3>
                <h4>{ languageJson.game.CHESShelpPopoverPart6.split('\n').map((el) => [el, <br/>]) }</h4><br/>
                <h3>{ languageJson.game.CHESShelpPopoverPart3.split('\n').map((el) => [el, <br/>]) }</h3>
                <h4>{ languageJson.game.CHESShelpPopoverPart7.split('\n').map((el) => [el, <br/>]) }</h4><br/>
                <h3>{ languageJson.game.CHESShelpPopoverPart4.split('\n').map((el) => [el]) } { json.version }</h3>
            </Popover.Body>
        </Popover>
    )

    const scorePopover = (
        <Popover id="popover-score m-0 p-0" style={ { width: '200px' } }>
            <Popover.Header as="h3">
                <div>
                    { languageJson.game.Score }
                    <span data-cy="sidebar-score-close" style={ { float: 'right', cursor: 'pointer' } } onClick={ () => {
                        setScoreShow(false)
                    } }>X</span>
                </div>
            </Popover.Header>
            <Popover.Body>
                <table className="w-100 h-100 border-gray text-center">
                    <thead>
                    <tr>
                        <td></td>
                        <td className="heading">{ 'You' }</td>
                        <td className="heading">{ opponentInfo?.opponentName }</td>
                    </tr>
                    </thead>
                    <tbody className="p-0 m-0">
                    <tr>
                        <td className="heading">{ languageJson.game.points }</td>
                        <td>{ myWin + myDraw }</td>
                        <td>{ oppWin + oppDraw }</td>
                    </tr>
                    <tr>
                        <td className="heading">{ languageJson.game.win }</td>
                        <td>{ myWin }</td>
                        <td>{ oppWin }</td>
                    </tr>
                    <tr>
                        <td className="heading">{ languageJson.game.loss }</td>
                        <td>{ myLoss }</td>
                        <td>{ oppLoss }</td>
                    </tr>
                    <tr>
                        <td className="heading">{ languageJson.game.draw }</td>
                        <td>{ myDraw }</td>
                        <td>{ oppDraw }</td>
                    </tr>
                    </tbody>
                </table>
            </Popover.Body>
        </Popover>
    )

    return (
        <div className="sidebar h-100">
            <div>
                <OverlayTrigger
                    flip={ false }
                    onHide={ () => {} }
                    onToggle={ (state) => setShow(state) }
                    trigger={ ['hover', 'focus'] }
                    defaultShow={ false }
                    show={ show }
                    popperConfig={ {} }
                    target={ null }
                    delay={ { show: 250, hide: 0 } }
                    placement="right"
                    overlay={ logoPopup }
                    rootClose
                    id="logoButton"
                >
                    <div
                        className="item logo"
                        onClick={ () => {
                            window.location.href = process.env.REACT_APP_REDIRECT_URL
                        } }
                    >
                        <img data-cy="sidebar-logo" src={ PalatarLogo } alt="palatar-logo"/>
                    </div>
                </OverlayTrigger>

                <OverlayTrigger
                    trigger={ ['click'] }
                    placement="right"
                    overlay={ languagePopover }
                    rootClose
                    show={ languageShow }
                    flip={ false }
                    onHide={ () => {
                    } }
                    onToggle={ (state) => setLanguageShow(state) }
                    defaultShow={ false }
                    popperConfig={ {} }
                    target={ null }
                    delay={ { show: 250, hide: 0 } }
                >
                    <div data-cy="sidebar-globe" className="item" onClick={ () => setLanguageShow(true) }>
                        <VscGlobe/>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    trigger={ ['click'] }
                    placement="right"
                    overlay={ scorePopover }
                    rootClose
                    show={ scoreShow }
                    flip={ false }
                    onHide={ () => {
                    } }
                    onToggle={ (state) => setScoreShow(state) }
                    defaultShow={ false }
                    popperConfig={ {} }
                    target={ null }
                    delay={ { show: 250, hide: 1000 } }
                >
                    <div data-cy="sidebar-question" className="item">
                        <img data-cy="sidebar-score" src={ GrScoreCard } alt="scorecard-icon" className="h-50 w-50"/>
                    </div>
                </OverlayTrigger>
            </div>
            <div>
                <OverlayTrigger
                    trigger={ ['click'] }
                    placement="right"
                    overlay={ infoPopover }
                    rootClose
                    show={ infoShow }
                    flip={ false }
                    onHide={ () => {
                    } }
                    onToggle={ (state) => setInfoShow(state) }
                    defaultShow={ false }
                    popperConfig={ {} }
                    target={ null }
                    delay={ { show: 250, hide: 0 } }
                >
                    <div data-cy="sidebar-info" className="item">
                        <BsInfo/>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    trigger={ ['click'] }
                    placement="right"
                    overlay={ helpPopover }
                    rootClose

                    show={ faqShow }
                    flip={ false }
                    onHide={ () => {
                    } }
                    onToggle={ (state) => setFaqShow(state) }
                    defaultShow={ false }
                    popperConfig={ {} }
                    target={ null }
                    delay={ { show: 250, hide: 1000 } }
                >
                    <div className="item">
                        <BsQuestion/>
                    </div>
                </OverlayTrigger>
                <div data-cy="sidebar-exit"
                     className="item exit"
                     onClick={ () => {
                         window.sendToGA('Exit Button Clicked')
                         window.location.href = process.env.REACT_APP_REDIRECT_URL
                     } }
                >
                    <img src={ ExitIcon } alt="exit-icon"/>
                </div>
            </div>
        </div>
    )
}
