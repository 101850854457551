import React from 'react'
import { CapturedPieces, Timer } from '../components'

import WhiteLabel from '../assets/whites.svg'
import BlackLabel from '../assets/blacks.svg'
import './game-info.scss'

const Lablers = {
  b: BlackLabel,
  w: WhiteLabel,
};

export const GameInfo = ({ color, name, timerProps }) => {
  return (
    <div className="game-info">
      <div className="content">
        <div className="d-flex flex-column justify-content-center">
          <div>
            <span className="name">{name}</span>
            <Timer {...timerProps} />
          </div>
        </div>

        <img className="labeler" src={Lablers[color]} alt="game-info-icon"/>

        <CapturedPieces color={color} />
      </div>
    </div>
  );
};
