import React, { Fragment, useContext } from 'react'
import { LayoutContext } from '../../store'
import json from '../../../package.json'

export const FAQ = () => {
  const { state: layoutState } = useContext(LayoutContext);

  const { languageJson } = layoutState;

  return (
    <Fragment>
      <p className="title text-center">
        {languageJson.game.CHESShelpPopoverHeader}
      </p>
      <div>
        <p className="fs-1 t-orange text-center">
          {languageJson.game.CHESShelpPopoverPart1.split("\n").map((el) => [
            el,
            <br />,
          ])}
        </p>

          <p className="fs-3 t-orange text-justify">
              {languageJson.game.CHESShelpPopoverPart5.split("\n").map((el) => [
                  el
              ])}
          </p>

        <p className="fs-1 t-orange text-center">
          {languageJson.game.CHESShelpPopoverPart2.split("\n").map((el) => [
            el,
            <br />,
          ])}
        </p>

          <p className="fs-3 t-orange text-justify">
              {languageJson.game.CHESShelpPopoverPart5.split("\n").map((el) => [
                  el
              ])}
          </p>

        <p className="fs-1 t-orange text-center">
          {languageJson.game.CHESShelpPopoverPart3.split("\n").map((el) => [
            el,
            <br />,
          ])}
        </p>

          <p className="fs-3 t-orange text-justify">
              {languageJson.game.CHESShelpPopoverPart5.split("\n").map((el) => [
                  el
              ])}
          </p>

          <p className="fs-3 t-orange text-center">
              {languageJson.game.CHESShelpPopoverPart4.split("\n").map((el) => [
                  el
              ])} {json.version}
          </p>
      </div>
    </Fragment>
  );
};
